<template>
  <div class="full-screen-loader">
    <UISpinner/>
  </div>
</template>

<style>
.full-screen-loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  z-index: 10000;
}
</style>